@import '../../css/breakpoints.scss';

.slider-container {
  z-index: 1;
  display: block;
  width: 100%;
  height: 95vh;

  @include md {
    min-width: 100%;
    height: 95vh;
  }
}

.imgbox {
  z-index: 0;
  display: flex;
  align-items: center;
  width: 100%;
  background-position: center center;
  color: #fff;
}

@mixin img_in_slider($sec) {
  padding-top: 80px;
  position: absolute;
  width: 100%;
  height: 80%;
  z-index: 1;
  animation: xfade 30s $sec infinite;
  animation-timing-function: cubic-bezier(0, 1, 0.05, 1);
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.6);
  background-size: cover;

  // @include md {
  //   height: 80%;
  // }
}

#img1 {
  @include img_in_slider(-0s);
  background-image: url(./img/principal.jpg);
}

#img2 {
  @include img_in_slider(-10s);
  background-image: url(./img/arboles.jpg);
}

#img3 {
  @include img_in_slider(-20s);
  background-image: url('./img/child.jpg');
}

@keyframes xfade {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  33% {
    opacity: 1;
  }
  53% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.contenedor_textos {
  width: 100%;
  padding: 20px;
  line-height: 20px;

  @include md {
    max-width: $column-width;
    margin: 0 auto;
  }

  .titulo {
    color: #fff;
    font-weight: 700;
    font-size: 2.5em;
    margin: 20px 0 30px 0;
    line-height: 50px;
    @include md {
      font-size: 4em;
      line-height: 1em;
    }
  }

  .subtitulo {
    color: #ddd;
    font-size: 25px;
    line-height: 35px;
    margin: 20px 0 20px 0;
    @include md {
      font-size: 40px;
      line-height: 60px;
    }
  }
}
