@import '../../css/breakpoints.scss';

.productos {
  width: calc(100% - 40px);

  padding-left: 20px;
  padding-right: 20px;

  margin-bottom: 100px;

  @include md {
    width: ($column-width * 0.95);
    margin: 0 auto;
  }

  .titulo {
    // border: 1px solid #222;
    // width: 60%;
    font-size: 2.5em;
    font-weight: 700;
    margin-bottom: 50px;
    // float: left;
    display: block;
  }

  .titulo_left {
    width: 60%;
    float: left;
  }

  .titulo_right {
    display: block;
    width: 30%;
    float: right;

    .btn_catalogo {
      text-align: right;
      float: right;
      margin-top: 15px;
      a {
        padding: 10px 20px;
        background-color: #0b52ac;
        text-align: center;
        font-weight: bold;
        color: white;
        transition: all 0.5s ease;

        &:hover {
          background-color: lighten(#0b52ac, 20%);
          border-radius: 20px;
          cursor: pointer;
        }
      }
    }
  }

  .lista {
    width: 100%;
  }
}
