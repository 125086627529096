@import '../../css/breakpoints.scss';

.contacto {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  background-size: cover;
  background-image: url(./img/bg.jpg);
  box-shadow: inset 0 0 0 2000px rgba(255, 255, 255, 0.6);
  background-size: cover;

  @include md {
    min-width: 1200px;
    padding-top: 100px;
    padding-bottom: 150px;
  }
}

.contenedor {
  width: 100%;
  margin: 0 auto;

  .titulo {
    font-size: 50px;
    font-weight: 700;
    margin-bottom: 50px;
    margin-left: 20px;
    @include md {
      width: $column-width * 0.95;
      margin: 20px auto;
    }
  }
}

.cuerpo {
  width: 80%;
  margin-left: 20px;
  @include md {
    width: $column-width * 0.95;
    margin: 20px auto;
    display: grid;
    grid-template-columns: 0.7fr 1.3fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-template-areas: 'datos formulario';
    margin-top: 20px;
  }
}
a {
  .facebook_logo {
    margin-top: 5px;
    width: 50px !important;
    transition: all 0.5s ease;

    &:hover {
      filter: saturate(5);
    }
  }
}

.cuerpo > .columna_izquierda {
  grid-area: datos;

  .horario_servicio > .subtitulo {
    background-color: #5500e8;
  }

  .correo_electronico > .subtitulo {
    background-color: #e86400;
  }

  .telefonos > .subtitulo {
    background-color: #e8a200;
  }

  .redes_sociales > .subtitulo {
    background-color: purple;
  }

  .subtitulo {
    color: #fff;
    border-radius: 50px;
    width: 200px;
    padding: 10px 20px;
    font-size: 20px;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .datos {
    margin-bottom: 30px;
    font-size: 15px;
    line-height: 30px;

    a {
      color: #222;
      font-weight: 700;
    }
  }
}

.cuerpo > .columna_derecha {
  grid-area: formulario;

  @include md {
    width: 400px;
    margin-left: 400px;
  }
  .control {
    padding: 10px 0;

    label {
      font-weight: bold;
      margin: 10px 0;
    }

    input {
      margin: 10px 0;
      border: 0;
      padding: 10px 20px;
      width: 100%;
    }

    textarea {
      margin: 10px 0;
      border: 0;
      padding: 10px 20px;
      width: 100%;
    }

    button {
      font-weight: bold;
      color: #fff;

      border: 0;
      padding: 15px 30px;
      background-color: #00bb2c;

      &:disabled,
      &[disabled] {
        border: 1px solid #999999;
        background-color: #cccccc;
        color: #666666;
      }
    }
  }
}
