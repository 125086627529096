@import '../../../css/breakpoints.scss';

@mixin boton_descargas {
  .wrapperButton {
    // background-color: crimson;
    word-break: break-all;
    word-wrap: break-word;
    margin-top: 20px;
    margin-bottom: 30px;
    margin-right: 10px;
    display: block;

    @include md {
      display: inline-block;
    }

    a.boton {
      padding: 10px 20px;
      background-color: #0b52ac;
      text-align: center;
      font-weight: bold;
      color: white;
      transition: all 0.5s ease;

      &:hover {
        background-color: lighten(#0b52ac, 20%);
        border-radius: 20px;
        cursor: pointer;
      }
    }
  }
}

.producto.md {
  display: none;
}

.producto.sm {
  margin-bottom: 50px;
  .encabezado {
    width: 100%;
    margin-bottom: 30px;
    img {
      width: 100%;
    }
  }
}

.producto.sm {
  .descargas {
    .titulo {
      font-size: 1.4em;
      color: #dc0084;
      margin-bottom: 10px;
    }

    button {
      border: 0;
      background-color: #000872;
      padding: 10px 20px;
      color: #fff;
      font-weight: bold;
      margin: 0 5px;
    }

    @include boton_descargas;
  }
}

.producto.sm > .contenedor {
  width: 100%;
  display: grid;
  grid-template-columns: 0.5fr 1.5fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: 'botella_logos textos';
  margin-bottom: 20px;

  .resaltado {
    color: #dc0084;
    font-weight: bold;
  }

  .botella_logos {
    grid-area: botella_logos;
    width: 100%;
    .botella {
      width: 100%;
      img {
        width: 100%;
      }
    }

    .presentacion {
      width: 100%;
      img {
        width: 100%;
      }
    }

    .logo_covid_19 {
      width: 100%;
      img {
        width: 100%;
      }
    }
  }
  .textos {
    grid-area: textos;
    padding-left: 10px;

    .texto_breve {
      line-height: 1.4em;
      margin-bottom: 20px;
    }

    .texto_beneficios {
      $margen_bullet: -1em;
      margin-left: 1em;
      margin-bottom: 20px;
      .titulo {
        color: #dc0084;
        font-weight: bold;
        font-size: 1.2em;
        margin-bottom: 5px;
        margin-left: $margen_bullet;
      }

      ul {
        list-style: none;
        line-height: 1.5em;
      }

      ul li::before {
        content: '\25A0';
        color: #0090ff;
        font-weight: bold;
        font-size: 1.2em;
        display: inline-block;
        width: $margen_bullet * -1;
        margin-left: $margen_bullet;
      }

      ul li {
        margin-bottom: 15px;
      }
    }
  }
}

@include md {
  .producto.sm {
    display: none;
  }

  .producto.md {
    display: block;
    width: 100%;
    margin-bottom: 100px;

    .encabezado {
      width: 100%;

      img {
        width: 100%;
      }
    }

    .cuerpo {
      width: 100%;
      display: grid;
      grid-template-columns: 0.5fr 2fr 0.8fr;
      grid-template-rows: 1fr;
      gap: 0px 0px;
      grid-template-areas: '. . .';

      .botella {
        width: 1px;
        text-align: center;
        img {
          max-height: 500px;
        }
      }

      .center {
        background-color: crimson;
      }

      .right {
        background-color: crimson;
      }
    }
  }

  .producto__texto_centro {
    margin-left: 80px;
    .texto_breve {
      font-size: 1.3em;
      line-height: 1.5em;
      margin-bottom: 15px;
    }

    .resaltado {
      color: #dc0084;
      font-weight: bold;
    }

    .texto_beneficios {
      $margen_bullet: -1.3em;

      margin-left: 1.74em;
      margin-bottom: 15px;

      .titulo {
        width: 100%;
        font-size: 1.4em;
        color: #dc0084;

        margin-left: $margen_bullet;
        margin-bottom: 10px;
      }

      ul {
        list-style: none;
        line-height: 1.5em;
      }

      ul li::before {
        content: '\25A0';
        color: #0090ff;
        font-weight: bold;
        font-size: 1.2em;
        display: inline-block;
        width: $margen_bullet * -1;
        margin-left: $margen_bullet;
      }

      ul li {
        margin-bottom: 15px;
      }
    }

    .descargas {
      // overflow: auto;
      // word-break: break-all;
      // word-wrap: break-word;
      .titulo {
        width: 100%;
        font-size: 1.4em;
        color: #dc0084;
        margin-bottom: 20px;
      }
      @include boton_descargas;
    }

    .lista_beneficios {
      font-size: 1.2em;
      line-height: 1.3em;
    }
  }

  .producto__derecha {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: space-around;
    align-items: stretch;
    align-content: stretch;

    &:after {
      display: block;
      content: '';
      margin: 10px;
      flex: 999 999 auto;
    }

    .item {
      flex: 1 0 auto;
      margin: 10px;
    }

    .presentacion {
      width: 100%;
      img {
        width: 100%;
      }
    }

    .logo_covid_19 {
      width: 100%;
      text-align: center;
      img {
        // padding: 10px;
        width: 100%;
      }
    }
  }
}
