@import '../../css/breakpoints.scss';

.distribuidores {
  width: calc(100% - 40px);

  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 50px;

  @include md {
    width: ($column-width * 0.95);
    margin: 0 auto;
    margin-bottom: 100px;
  }
  .titulo {
    font-size: 2.5em;
    font-weight: 700;
    margin-bottom: 50px;
  }

  .movil {
    .img-volante {
      img {
        width: 100%;
      }
    }

    @include md {
      display: none;
    }
  }

  .nomovil {
    display: none;
    @include md {
      display: block;
      .img-volante {
        img { 
          width: 100%;
          min-width: 1100px;
        }
      }
    }
  }

  .distribuidor_footer {
    text-align: center;
    margin-top: 20px;

    .subtitulo {
      font-size: 2em;
      margin-bottom: 10px;
      width: 100%;
    }

    .telefono {
      font-size: 1.5em;
      padding-top: 5px;
      padding-bottom: 5px;

      .telefono__espacio {
        display: none;
        @include md {
          display: block;
          margin-bottom: 10px;
        }
      }

      @include md {
        width: 300px;
        display: inline-block;
      }

      .telefono__mensaje {
        color: #222;
        padding-top: 5px;
        padding-bottom: 5px;
      }

      .telefono__numero {
        background-color: #b30086;
        color: white;
        width: 270px;
        margin: 6px auto;
        padding: 20px 20px;
        transition: all 0.5s ease;
        border-radius: 5px;

        &:hover {
          background-color: lighten(#b30086, 15%);
          border-radius: 1000px;
        }
      }
    }

    .whatsapp {
      font-size: 1.5em;

      background-color: #54007d;
      color: white;
      width: 270px;
      margin: 5px auto;
      padding: 20px 20px;

      transition: all 0.5s ease;
      border-radius: 5px;

      @include md {
        width: 300px;
        display: inline-block;
        margin: 0 auto 0 50px;
        padding-top: 0.85em;
        padding-bottom: 0.8em;
        // padding: 19px 10px;
      }

      &:hover {
        background-color: lighten(#54007d, 15%);
        border-radius: 1000px;
      }

      .whatsapp__logo {
        width: 30px;
        display: inline-block;

        img {
          width: 30px;
          margin-top: -0.5em;
        }
      }

      .whatsapp__mensaje {
        margin-left: 10px;
        margin-top: -0.5em;
        vertical-align: middle;
        display: inline-block;
      }
    }
  }

.distribuidores_tarjetas{

  h2{
    margin-top: 50px;
    text-align: center;
    font-size: 25px;
    font-weight: 600;
  }

  .distribuidores_lista
  {
    display: grid;
    grid-template-columns: auto;

    @include md {
      grid-template-columns: auto auto;      
    }

    .img-tarjeta{
      padding: 40px;
      text-align: center;
    }
  }
}
}
