@import '../../css/breakpoints.scss';

.header {
  z-index: 5;
  width: 100%;
  font-weight: 700;
  letter-spacing: 0.06em;
  text-align: center;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 20px;

  @include md {
    height: 1px;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    left: 50%;
    transform: translate(-50%, 0);
  }

  @include lg {
    height: 20px;
  }
}

a {
  text-decoration: none;
  color: #fff;
}

.espaciovacio {
  .contenedor {
    width: 140px;
    font-size: 0.7em;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 0px 0px;
    grid-template-areas:
      'telefono_1'
      'telefono_2';

    .telefono_1 {
      background-color: rgba(0, 0, 0, 0.6);
      border-radius: 1px;
      margin-top: 20px;
      padding: 10px 5px;
      // padding: 10px 15px 1px;
      margin-left: 10px;
      grid-area: telefono_1;

      @include sm {
        border-radius: 10px;
        padding: 10px 15px;
      }
    }

    .telefono_2 {
      background-color: rgba(0, 0, 0, 0.6);
      border-radius: 1px;
      margin-left: 10px;
      padding: 10px 5px;
      margin-top: 20px;
      grid-area: telefono_2;
      @include sm {
        border-radius: 10px;
        padding: 10px 15px;
      }
    }

    @include sm {
      width: 200px;
    }

    @include md {
      font-size: 1em;
      width: 400px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr;
      gap: 0px 0px;
      grid-template-areas: 'telefono_1 telefono_2';
      margin-bottom: 20px;
      .telefono_1 {
        grid-area: telefono_1;
      }

      .telefono_2 {
        grid-area: telefono_2;
      }
    }
  }

  width: 100%;
}

/*Desktop View*/
.header {
  display: flex;
  // background-color: rgba(0, 0, 0, 0.5);
  justify-content: space-between;
  align-items: center;
  // margin-top: 20px;

  // padding: 0px 40px;
  // height: 140px;
}
.logo-nav {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo {
  width: 100px;
  height: 100px;

  @include md {
    margin-top: 40px;
    margin-left: 40px;
  }

  @include rwd(1440) {
    width: 150px;
    height: 150px;
    margin-top: 90px;
    margin-left: 90px;
  }

  img {
    width: 100%;
    // border-radius: 1000px;
  }
}
.nav-options {
  float: right;
  padding-left: 25px;
  margin-right: 20px;
  display: grid;
  font-size: 17px;
  grid-template-columns: repeat(4, auto);
  grid-gap: 50px;
  list-style-type: none;
}
.mobile-option {
  display: none;
}

.option :hover {
  color: white;
}
.mobile-menu {
  display: none;
}
@media (max-width: 648px) {
  /*Mobile View */
  .header {
    padding: 20px 20px 40px;
    // margin-top: 20px;
  }
  .logo {
    width: 80px;
    height: 80px;

    img {
      // border-radius: 1000px;
      width: 100px;
    }
  }

  .nav-options {
    display: flex;
    width: 100%;
    height: auto;
    padding-bottom: 30px;
    position: absolute;
    top: 110px;
    left: -100%;
    opacity: 0;
    transition: all 0.5s ease;
    flex-direction: column;
    list-style-type: none;
    grid-gap: 0px;
    text-align: center;
    font-size: 30px;
  }

  .nav-options.active {
    background: #222;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    align-content: center;
    padding-left: 0px;
    margin-left: -10px;
  }

  .menu-icon {
    width: 45px;
    height: 45px;
    margin-right: 40px;
  }
  .option {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10vw;
    padding: 30px 60px 10px;
  }
}
.mobile-menu {
  display: block;
  color: #fff;
}
